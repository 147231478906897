* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Calibri";

  &::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(150, 141, 141, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #00ADAD;
  }
}

body {
  background: rgb(243, 253, 255);
  //background: radial-gradient(circle, rgba(229,255,255,0.1) 0%, rgba(180,247,247,0.2) 92%, rgba(0,173,173,0.15) 100%);
}